/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {ExternalLink} from '../components/external-link';
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "2024 syyskauden toiminta"), "\n", React.createElement(_components.p, null, "Uusien ilta on sunnuntaina 25.8. Oulunkylän nuorisotalo Nuotalla Oulunkylässä. Mukaan saa tulla tutustumaan\r\nminä tahansa sunnuntaina, mutta uusien ilta erityisesti on sitä varten tarkoitettu. Tule rohkeasti mukaan!"), "\n", React.createElement(_components.p, null, "Viikottaiset sunnuntaitapaamiset ovat olleet kesän ajan tauolla mutta jatkuvat sunnuntaina\r\n18.8. Näistä sekä uusien illasta lisää Kalenteri-sivulta."), "\n", React.createElement(_components.p, null, "Taistelutreenit jatkuvat ainakin vielä elokuun ajan näillä näkymin ulkona Kannelmäessä ja jossain vaiheessa\r\nsyyskuun aikana siirtynemme sisätiloihin Viikkiin."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
